import { render, staticRenderFns } from "./StrategyParameterConfirmation.vue?vue&type=template&id=196d1efa&scoped=true"
import script from "./StrategyParameterConfirmation.vue?vue&type=script&lang=js"
export * from "./StrategyParameterConfirmation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "196d1efa",
  null
  
)

export default component.exports